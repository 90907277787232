import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useSignOutMutation } from '@bvi/admin-panel/entities/admin-auth/api-slice';
import { selectCurrentUser } from '@bvi/admin-panel/entities/admin-auth/selectors';
import { AdminPanelRoutesPaths } from '@bvi/admin-panel/shared/constants/routes';
import { Header as BaseHeader } from '@bvi/common-components';

const DEFAULT_CASE_LIST_QUERY = new URLSearchParams({
  page: '1',
  limit: '25',
  classAction: '1',
}).toString();

export const Header = () => {
  const { t } = useTranslation();
  const [signOutRequest] = useSignOutMutation();
  const currentUser = useSelector(selectCurrentUser());

  const headerItems = [
    {
      label: t('header.database'),
      path: `${AdminPanelRoutesPaths.CASES_LIST}?${DEFAULT_CASE_LIST_QUERY}`,
    },
    {
      label: t('header.pending_cases'),
      path: AdminPanelRoutesPaths.PENDING_CASES_LIST,
    },
    {
      label: t('header.clients'),
      path: AdminPanelRoutesPaths.CLIENTS_LIST,
    },
    {
      label: t('header.crate_case'),
      path: AdminPanelRoutesPaths.CASES_CREATE,
    },
  ];

  const navItems = [
    {
      label: t('header.logout'),
      action: () => signOutRequest(),
    },
  ];

  return (
    <BaseHeader
      items={headerItems}
      navItems={navItems}
      userName={currentUser.email}
    />
  );
};
